/*
 *   Module Loader
 *   Consumes an array of .js-modules and parses data-module attribute to produce an array
 *   of module names to be loaded.
 *
 *   Markup must include class="js-module" and
 *   data-module="['my-module-1', 'my-module-2'"]
 *
 */

import { camelize } from '@/ui/scripts/lib/camelize'

type IndexableFunc = { [key: string]: () => void }

function load(key: string) {
  const modules: IndexableFunc = {
    footer: async () => {
      const { default: footer } = await import('@/ui/templates/02-components/02-footer/footer')
      footer()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    header: async () => {
      const { default: header } = await import('@/ui/templates/02-components/01-header/header')
      header()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    cardBrowser: async () => {
      const { default: cardBrowser } = await import('@/ui/templates/02-components/06-card-browser/card-browser')
      cardBrowser()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    staffMembers: async () => {
      const { default: staffMembers } = await import('@/app/views/staff-members/staff-members')
      staffMembers()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    iconBlock: async () => {
      const { default: iconBlock } = await import('@/ui/templates/02-components/04-icon-block/icon-block')
      iconBlock()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    hero: async () => {
      const { default: hero } = await import('@/ui/templates/02-components/03-hero/hero')
      hero()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    featureContent: async () => {
      const { default: featureContent } = await import(
        '@/ui/templates/02-components/05-feature-content/feature-content'
      )
      featureContent()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    breadcrumb: async () => {
      const { default: breadcrumb } = await import('@/ui/templates/02-components/08-breadcrumb/breadcrumb')
      breadcrumb()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    productSpecification: async () => {
      const { default: productSpecification } = await import('@/app/views/product-specification/product-specification')
      productSpecification()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    productListing: async () => {
      const { default: productListing } = await import('@/app/views/product-listing/product-listing')
      productListing()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    },
    pageTitle: async () => {
      const { default: pageTitle } = await import('@/ui/templates/02-components/07-page-title/page-title')
      pageTitle()
      if (process.env.NODE_ENV) console.log(`${key} loaded`)
    }
  }

  try {
    return modules[key]()
  } catch (e) {
    throw { errorCode: 404, message: `Module ${key} not found.` }
  }
}

function init(modules: HTMLElement[]) {
  if (!modules) return
  const moduleList: string[] = []
  modules.forEach((element) => {
    try {
      const moduleNames = element.dataset.module
      if (moduleNames) {
        const moduleArray: string[] = JSON.parse(moduleNames.replace(/'/g, '"'))
        moduleArray.forEach((item: string) => {
          const module = camelize(item)
          if (moduleList.indexOf(module) === -1) moduleList.push(module)
        })
      }
    } catch (e) {
      console.error('JS name format incorrect in data attribute:')
      console.log(moduleList)
    }
  })
  moduleList.forEach((module) => {
    load(module)
  })
}

export default init
